<template>
  <div v-if="isshowgrid">
    <va-card title="IPTV Provider List">
      <div class="row align--center">
        <div class="flex xs12 md6">
          <va-input class="va-input-search-container"
            :value="term"
            placeholder="search"
            @input="search"
            removable
          >
            <va-icon name="fa fa-search" slot="prepend" />
          </va-input>
        </div>
        <div class="flex xs12 md3 offset--md3">
          <va-select
          label="Provider"
          placeholder="Select Provider"
          v-model="filter"
          textBy="brand"
          @input="getFilter(filter)"
          :options="providerArr"
          :error="!!providerErrors.length"
          :error-messages="providerErrors"
        />
        </div>
      </div>
      <va-button 
        v-if="genresData.length > 0"
        :color="selectOtns.color" 
        class="select_all" small 
        :icon="selectOtns.icon" 
        @click="selectOtns.click">{{selectOtns.value}}</va-button>
      <va-data-table
        :fields="fields"
        :data="filteredData"
        :per-page="parseInt(perPage)"
        clickable
      >
      <template slot="checkbox" slot-scope="props">
        <va-checkbox 
          :value="props.rowData.checkbox_value" 
          @input="select(props.rowData)" 
        />
      </template>
      </va-data-table>

       <template slot="actions">
        <va-button icon="fa fa-check" :disabled="genresData.length == 0" v-if='!supportRole' @click="updateData()">Update</va-button>
      </template>
        
    </va-card>
  </div>
  <div v-else-if='isshowForm'>
    <div>
      <va-card :title="title">
        <template slot="actions">
          <va-button color="gray" @click="list()">List</va-button>
        </template>
        <div>
          <form>
            <span class="va-form-label va-form-required-label">Genres</span>
            <va-input
              v-model.trim="genres"
              type="text"              
              placeholder="Enter Genres"
              :error="!!genresErrors.length"
              :error-messages="genresErrors"
            />
            <span class="va-form-label va-form-required-label">Priority</span>
            <va-input
              v-model="order"
              type="number"              
              placeholder="Enter Priority"
              :error="!!priorityErrors.length"
              :error-messages="priorityErrors"
            />
            <div class="d-flex justify--end mt-3">
              <va-button type="submit" class="my-0 va-button-normal-cancel" @click.prevent="list()">Cancel</va-button>
              <va-button v-if="isCreate" type="submit" class="my-0" @click.prevent="createGenres()">Create</va-button>
              <va-button v-if="isUpdate" type="submit" class="my-0" @click.prevent="updateGenres()">Update</va-button>              
            </div>
          </form>
        </div>
      </va-card>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import vueResource from 'vue-resource'
import { search } from '../../i18n/search.js'
import config from '../../i18n/en.json'
import { debounce } from 'lodash'
Vue.use(vueResource)
export default {
  name: 'iptv_provider',
  beforeCreate () {
    var role = this.$cookies.get('userRole')
    const loader = Vue.$loading.show({ width: 40, height: 40 })
	    this.$http.get(config.menu.host + '/provider').then(resp => {
	      loader.hide()
        resp.body = resp.body.filter(function(prop,i){
          prop['id']=i+1
          return prop.iptv
        })
	      this.providerArr = resp.body
	      this.isshowForm = false
	      this.isshowgrid = true
	      if(role=='SUPPORT'){
         	 this.supportRole=true
              }
	    }, error => {
	      loader.hide()
	      if (error && error.body) {
	        Vue.notify({ text: error.body, type: 'error' })
	      }
	    })
  },
  data () {
    return {
      selectOtns:{
        icon:'entypo entypo-check',
        click:this.selectAll,
        value:"Select All",
        color:"primary"
      },
      term: null,
      perPage: '10',
      perPageOptions: ['4', '6', '10', '20'],
      isshowgrid: false,
      isshowForm: false,
      isCreate: false,
      isUpdate: false,
      showRemoveModal: false,
      filter:'',
      title: '',
      sc_category : '',
      genresData: [],
      providerErrors:[],
      currentProvider :'',
      currentProviderId:'',
      supportRole:false
    }
  },
  computed: {
    // actionBtnsDisabled () {
    //   const cust_array_selected = this.genresData.filter(cust => cust.checkbox_value)
    //   if (cust_array_selected.length > 0) {
    //     return false
    //   }
    //   return true
    // },
    fields () {
      return [{
        name: '__slot:checkbox',
      },
      {
        name: '__slot:marker',
        width: '30px',
        dataClass: 'text-center',
      },
      {
        name: 'package_name',
        title: 'Package Name',
      },
      {
        name: '__slot:actions',
        dataClass: 'text-right',
      }]
    },
    filteredData () {
      return search(this.term, this.genresData)
    },
  },
  methods: {
    select (cust) {
      this.genresData[cust.id].checkbox_value = !this.genresData[cust.id].checkbox_value
      const cust_array_selected = this.genresData.filter(cust => cust.checkbox_value)
      if (cust_array_selected.length == 0) {
        this.selectOtns = {
          icon:'entypo entypo-check',
          click:this.selectAll,
          value:"Select All",
          color:"primary"
        }
        // this.getFilter(this.currentProviderId)
      } else {
        this.selectOtns = {
          icon:'entypo entypo-cancel',
          click:this.deSelectAll,
          value:"De Select All",
          color:"danger"
        }
        clearInterval(this.interval)
      }
    },
    selectAll(){
      let count = 0
      this.genresData.map((cust,i) => {
        if(cust.transcoding_status == 'inprogress') return
        cust.checkbox_value = true
        count++
      })
      if(!count) return
      this.genresData.map(cust => cust.checkbox_value = true)
      this.selectOtns = {
        icon:'entypo entypo-cancel',
        click:this.deSelectAll,
        value:"De Select All",
        color:"danger"
      }
      clearInterval(this.interval)
    },
    deSelectAll(){
      this.genresData.map(cust => cust.checkbox_value = false)
      this.selectOtns = {
        icon:'entypo entypo-check',
        click:this.selectAll,
        value:"Select All",
        color:"primary"
      }
    },
    getFilter(data){
      let index = 0
      this.currentProvider = data
      this.currentProviderId = data.provider_id
      this.existing = []
      var vm = this;
      this.$http.get(config.menu.host + '/iptv/'+data.provider_id).then(resp => {
        this.genresData = resp.body.map(function (item) {
            item.id = index++
            item.sno = index
            item.checkbox_value = item.status
            if(item.status){
              vm.existing.push(item)
            }
            return item
        })
        this.isshowgrid = true
      })
    },
    updateData () {
      var arr = [], delarr = [], samearr = [];
      var vm = this;

      this.existing.map(function(ele){
        if(!ele.checkbox_value){
          delete ele.id
          delarr.push(ele.package_id)
        }else{
         samearr.push(ele.package_id) 
        }
      })
      this.genresData.map(function (item) {
        if (item.checkbox_value) {
          if(samearr.indexOf(item.package_id) < 0){
            delete item.id
            item.package_name = vm.currentProvider.brand+'-'+item.package_name
            item.provider_category = [{id: 'LIVE'}]
            item.provider_type = 'DP'
            item.provider_name = vm.currentProvider.brand
            arr.push(item)
          }
        }
      })

      var payload = {data: arr,delarr:delarr}
      const loader = Vue.$loading.show({ width: 40, height: 40 })
      this.$http.post(config.menu.host + '/iptv',payload).then(responseData => {
        loader.hide()
        if (responseData && responseData.body) {
          Vue.notify({ text: responseData.body, type: 'success' })
        }
        this.list()
      }, errResponse => {
        loader.hide()
        if (errResponse && errResponse.body) {
          Vue.notify({ text: errResponse.body, type: 'error' })
        }
      })
    },
	  list () {
      this.isshowForm = false
      this.isshowgrid = true
      this.genresData = []
      this.filter = ''
    },
	  search: debounce(function (term) {
	   	this.term = term
	  }, 400),
  },
}
</script>
